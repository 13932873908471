import 'normalize.css';
import axios from 'axios';
import './index.less';

/**
 * userAgent 获取
 */
const getUesrAgent = () => {
  const u = navigator.userAgent;
  return {
    trident: u.indexOf('Trident') > -1, //IE内核
    presto: u.indexOf('Presto') > -1, //opera内核
    webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
    iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
    iPad: u.indexOf('iPad') > -1, //是否iPad
    webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
    weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
    qq: u.match(/\sQQ/i) == " qq" //是否QQ
  };
};

/**
 * 获取下载地址
 */
const getDownloadUrl = async () => {
  try {
    let data = await axios.get('https://bi.appbocai.com/api/config/app/download/url');
    return data.data.data;
  } catch(e) {
    return 'https://s.xingqiu123.com/admin/ktmlkh5o/Bocai-newhuahua_guanfang.apk';
  }
}

/**
 * 下载相关设置
 */
(async () => {
  const userAgent =  getUesrAgent();

  // 1 => ios
  // 2 => android
  let platform = 2;

  if (userAgent.ios) {
    platform = 1;
  }

  if (2 == platform) {
    $('#j-download-btn').on('click', () => {
      if (userAgent.android && userAgent.weixin) {
        alert('请在浏览器中打开');
      }
    });
    const downloadBtn = $('#j-download-btn');
    getDownloadUrl()
      .then(url => {
        downloadBtn.attr('href', url);
      });
  }
})();

/**
 * 页面相关设置
 */
(async () => {
  const tab = $('#j-tabs');
  const tabItems = $('#j-tabs .tab');
  const originClassName = tab.attr('class');

  tabItems.each((index, tabItem) => {
    $(tabItem).on('click', () => {
      tab.attr('class', `${originClassName} active-${index + 1}`);
      let to = 1 + index;
      $.fn.fullpage.moveTo(to);
    });
  });

  $('#container')
    .fullpage({
      sectionsColor: ['#1bbc9b', '#4BBFC3', '#7BAABE'],
      //设置颜色参数
      anchors: ['page1', 'page2', 'page3'],
      //定义锚链接
      // menu: '#menu'
      //绑定菜单，设定的相关属性与 anchors 的值对应后，菜单可以控制滚动
      onLeave(_, nextIndex) {
        let to = nextIndex;
        tab.attr('class', `${originClassName} active-${to}`);
      },
    });
})();
